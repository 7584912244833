import Icon from "@/utils/icon";
import React from "react";

export default function ChevronDownIcon({
  ...props
}: React.SVGAttributes<SVGElement>) {
  return (
    <Icon {...props}>
      <path d="M10.94 17.56a1.502 1.502 0 002.124 0l7.5-7.5a1.502 1.502 0 00-2.123-2.124L12 14.376 5.56 7.942a1.502 1.502 0 00-2.124 2.123l7.5 7.5.005-.005z" />
    </Icon>
  );
}
