import Icon from "@/utils/icon";
import React from "react";

export default function HamburgerIcon({
  ...props
}: React.SVGAttributes<SVGElement>) {
  return (
    <Icon {...props}>
      <path d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z" />
    </Icon>
  );
}
