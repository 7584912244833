import Icon from "@/utils/icon";

export default function ArrowUpIcon({
  ...props
}: React.SVGAttributes<SVGElement>) {
  return (
    <Icon width="24" fill="none" height="24" viewBox="0 0 24 24" {...props}>
      <path
        // strokeWidth="1"
        stroke="currentColor"
        d="M5 11.764c.742 0 1.85-.712 2.78-1.432 1.2-.926 2.247-2.032 3.046-3.3C11.425 6.08 12 4.928 12 4m0 0c0 .928.575 2.082 1.174 3.032.8 1.268 1.847 2.374 3.045 3.3.931.72 2.041 1.432 2.781 1.432M12 4v16"
      />
    </Icon>
  );
}
