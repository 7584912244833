"use client";

import ChevronDownIcon from "@/icons/chevron-down";
import cn from "@/utils/cn";
import Link from "next/link";
import { usePathname } from "next/navigation";
import {
  ReactNode,
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
  useMemo,
} from "react";
import { MobileMenuContext } from "../navbar";

type DropdownMenuItemType = {
  label: ReactNode;
  href: string;
};

type NavLinkProps = {
  href: string;
  label: ReactNode;
  subLinks?: DropdownMenuItemType[];
};

type DropdownContextType = {
  isDropdownOpen: boolean;
  setIsDropdownOpen: (isOpen: boolean) => void;
};

const DropdownContext = createContext<DropdownContextType>({
  isDropdownOpen: false,
  setIsDropdownOpen: () => {},
});

const isPathMatched = (pathname: string, route: string) =>
  route === pathname || (route.length > 2 && pathname.includes(route));

export default function NavLink({ href, label, subLinks }: NavLinkProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { setIsMobileMenuOpen } = useContext(MobileMenuContext);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  const pathname = usePathname();

  const isActive = isPathMatched(pathname, href);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const baseStyle =
    "h-10 md:h-auto flex items-center text-sm lg:text-base py-1 px-3 md:px-2 lg:px-3 rounded-lg text-white/80 transition-all";
  const activeStyle = isActive && "text-white bg-primary-300/50";

  const contextValue = useMemo(
    () => ({ isDropdownOpen, setIsDropdownOpen }),
    [isDropdownOpen, setIsDropdownOpen],
  );

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false);
  };

  if (!subLinks?.length) {
    return (
      <Link
        href={href}
        onClick={handleLinkClick}
        className={cn(baseStyle, activeStyle)}
      >
        {label}
      </Link>
    );
  }

  return (
    <DropdownContext.Provider value={contextValue}>
      <section ref={dropdownRef} className="relative">
        <button
          aria-label={`toggle ${label} menu`}
          onClick={toggleDropdown}
          className={cn(
            baseStyle,
            activeStyle,
            "w-full gap-2 md:gap-1 lg:gap-2",
            isDropdownOpen && "bg-primary-100/15 rounded-b-none md:rounded-lg",
          )}
        >
          <span>{label}</span>

          <span
            className={cn(
              "ml-auto md:ml-0 flex items-center justify-center",
              "[&>svg]:w-3.5 md:[&>svg]:w-2.5 lg:[&>svg]:w-3.5 [&>svg]:h-3.5 md:[&>svg]:h-2.5 lg:[&>svg]:h-3.5",
            )}
          >
            <ChevronDownIcon
              className={cn(
                "transition-all -rotate-90 md:rotate-0",
                isDropdownOpen && "-rotate-0 md:-rotate-180",
              )}
            />
          </span>
        </button>

        {isDropdownOpen && <DropdownMenu menuItems={subLinks} />}
      </section>
    </DropdownContext.Provider>
  );
}

type DropdownMenuProps = {
  menuItems: DropdownMenuItemType[];
};

function DropdownMenu({ menuItems }: DropdownMenuProps) {
  const { setIsDropdownOpen } = useContext(DropdownContext);
  const { setIsMobileMenuOpen } = useContext(MobileMenuContext);
  const pathname = usePathname();

  const handleMenuItemClick = () => {
    setIsDropdownOpen(false);
    setIsMobileMenuOpen(false);
  };

  return (
    <aside
      className={cn(
        "relative top-full left-0 w-full flex flex-col gap-0.5 rounded-b-lg mt-[1px]",
        "md:absolute md:top-full md:left-0 md:w-52 flex flex-col gap-0.5",
        "bg-primary-100/15 md:bg-white md:text-primary-300 md:shadow-lg md:rounded-md md:z-20 p-2",
      )}
    >
      {menuItems?.map((item) => {
        const isActive = isPathMatched(pathname, item.href);

        return (
          <Link
            key={item.href}
            href={item.href}
            onClick={handleMenuItemClick}
            className={cn(
              "py-2.5 px-3 md:py-2 transition-all text-sm rounded-lg",
              "hover:bg-primary-100/30 focus:bg-primary-100/30 active:bg-primary-100/50",
              isActive && "bg-primary-100/30",
            )}
          >
            {item.label}
          </Link>
        );
      })}
    </aside>
  );
}
