"use client";

import { useState, useEffect } from "react";
import Button from "../button";
import ArrowUpIcon from "@/icons/arrow-up";
import cn from "@/utils/cn";

export default function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Button
      onClick={scrollToTop}
      aria-label="scroll to top"
      className={cn(
        "fixed right-6 xl:right-15 h-12 w-12 rounded-full bottom-0",
        "bg-primary-400 text-white stroke-[1.5]",
        "opacity-0 transition-all",
        isVisible && "opacity-100 bottom-6 xl:bottom-15",
      )}
    >
      <ArrowUpIcon />
    </Button>
  );
}
