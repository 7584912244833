import cn from "@/utils/cn";
import React, { ButtonHTMLAttributes, ReactNode } from "react";

export type ButtonProps = {
  children: ReactNode;
  variant?: "ghost" | "outline" | "default";
} & ButtonHTMLAttributes<HTMLButtonElement>;

export default function Button({
  children,
  variant = "default",
  ...props
}: ButtonProps) {
  const defaultVariant = variant === "default" && "bg-primary-400 text-white";
  return (
    <button
      {...props}
      className={cn(
        "inline-flex justify-center items-center",
        defaultVariant,
        props.className,
      )}
    >
      {children}
    </button>
  );
}
