"use client";

import { Routes } from "@/app/routes";
import HamburgerIcon from "@/icons/hamburger";
import cn from "@/utils/cn";
import { createContext, useEffect, useMemo, useRef, useState } from "react";
import NavLink from "../nav-link";
import { ESTATES_ENUM } from "@/utils/constants";

type MobileMenuContextType = {
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (isOpen: boolean) => void;
};

export const MobileMenuContext = createContext<MobileMenuContextType>({
  isMobileMenuOpen: false,
  setIsMobileMenuOpen: () => {},
});

export function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuRef = useRef<HTMLDivElement>(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const contextValue = useMemo(
    () => ({ isMobileMenuOpen, setIsMobileMenuOpen }),
    [isMobileMenuOpen, setIsMobileMenuOpen],
  );

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      mobileMenuRef.current &&
      !mobileMenuRef.current.contains(event.target as Node)
    ) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <MobileMenuContext.Provider value={contextValue}>
      <section className="ml-auto" ref={mobileMenuRef}>
        <button
          aria-label="toggle menu"
          onClick={toggleMobileMenu}
          className={cn(
            "h-10 w-10 flex md:hidden items-center justify-center",
            "[&>svg]:w-7 [&>svg]:h-7",
          )}
        >
          <HamburgerIcon />
        </button>

        <nav
          className={cn(
            isMobileMenuOpen ? "flex" : "hidden",
            "absolute bg-primary-400 p-4 pt-3 rounded-b-xl md:rounded-b-[20px] w-full left-0 flex-col gap-1",
            "md:relative md:bg-transparent md:p-0 md:rounded-none md:w-auto md:flex md:left-auto md:flex-row",
            "lg:gap-2 xl:gap-4 animate-in slide-in-from-top-12 md:slide-in-from-top-0",
          )}
        >
          <span className="md:hidden lg:flex">
            <NavLink href={Routes.index()} label="Home" />
          </span>

          <NavLink
            href={Routes.ourEstates()}
            label="Our Estates"
            subLinks={[
              {
                label: "Phase 1",
                href: Routes.viewEstate(ESTATES_ENUM.PHASE_ONE),
              },
              {
                label: "Phase 2",
                href: Routes.viewEstate(ESTATES_ENUM.PHASE_TWO),
              },
              {
                label: "Phase 3",
                href: Routes.viewEstate(ESTATES_ENUM.PHASE_THREE),
              },
              {
                label: "Tiara",
                href: Routes.viewEstate(ESTATES_ENUM.TIARA),
              },
              {
                label: "Tiara 2.0",
                href: Routes.viewEstate(ESTATES_ENUM.TIARA_TWO),
              },
              {
                label: "Amen City FTZ",
                href: Routes.viewEstate(ESTATES_ENUM.CITY_FTZ),
              },
            ]}
          />

          <NavLink
            href={Routes.getStarted()}
            label="Get Started"
            subLinks={[
              {
                label: "Schedule Inspection",
                href: Routes.scheduleInspection(),
              },
              {
                label: "Buy a Property",
                href: Routes.buy(),
              },
              {
                label: "Rent a Home",
                href: Routes.rent(),
              },
              {
                label: "Shortlet",
                href: Routes.shortlets(),
              },
            ]}
          />

          <NavLink href={Routes.aboutUs()} label="About us" />

          {/* <NavLink
            href={Routes.aboutUs()}
            label="About us"
            subLinks={[
              {
                label: "The Company",
                href: Routes.company(),
              },
              {
                label: "The Founder",
                href: Routes.founder(),
              },
              {
                label: "Careers",
                href: Routes.careers(),
              },
              {
                label: "CSR",
                href: Routes.csr(),
              },
            ]}
          /> */}

          <NavLink href={Routes.faqs()} label="FAQs" />

          {/* <NavLink
            href={Routes.resources()}
            label="Resources"
            subLinks={[
              {
                label: "Gallery",
                href: Routes.gallery(),
              },
              {
                label: "FAQs",
                href: Routes.faqs(),
              },
              {
                label: "Blog",
                href: Routes.blog(),
              },
            ]}
          /> */}

          <NavLink href={Routes.contactUs()} label="Contact us" />
        </nav>
      </section>
    </MobileMenuContext.Provider>
  );
}
